@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400;500;700&display=swap');

/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  clear: both;
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  -webkit-font-feature-settings: "palt";
     -moz-font-feature-settings: "palt";
          font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  padding-top: 60px;
  &.active { overflow: hidden; }
  &[id^="PageCn"] {
    font-family: 'Noto Sans SC', sans-serif;
    font-weight: 400;
  }
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: #7f3a12;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

.innerBasic {
  margin: 0 auto;
  padding: 0 15px;
}

.font-yugo {
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-weight: 500;
}
.font-yumin {
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
}
.font-lora {
  font-family: 'Lora', serif;
  font-weight: 400;
}
.font-lato {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.font-meiryo {
  font-family: "メイリオ", "Meiryo", sans-serif;
}
.font-marugo {
  font-family: "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", sans-serif;
}
.font-notosc {
  font-family: 'Noto Sans SC', sans-serif;
  font-weight: 400;
}
.font-notoserif {
  font-family: 'Noto Serif SC', serif;
  font-weight: 500;
}

.pc_display,.disp_pc {
  display: none;
}
.sp_display,.disp_sp {
  display: block;
}
.sp_display_inline {
  display: inline;
}
.display_none {
  display: none !important;
}


/*####################　ヘッダ　####################*/
#Header {
  width: 100%;
  background-color: #fff;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  .header_cont {
    width: 100%;
    height: 60px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h1 {
    transform: translateY(-2px);
    a {
      display: block;
      background-repeat: no-repeat;
      background-position: left top;
      background-image: url(../img/header/logo.png);
      background-size: 125px auto;
      width: 125px;
      height: 30px;
      font-size: 0;
      text-decoration: none;
    }
  }
  #MenuButton {
    cursor: pointer;
    width: 25px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url(../img/header/icon_01.png);
    background-size: 25px auto;
  }
}

.active #Header #MenuButton {
  background-image: url(../img/header/icon_02.png);
}

.fixed #Header {
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
}

#PageInformation,
.pageConfirm,
.pageThanks {
  #Header { box-shadow: 0 4px 16px rgba(0,0,0,0.15); }
}

/*####################　メニュー　####################*/
#HeaderMenu_sp {
  position: fixed;
  top: -200%;
  left: 0;
  z-index: 48;
  width: 100%;
  height: calc( 100vh - 60px );
  background-color: #fff;
  text-align: center;
  transition: top 0.5s ease;
  .menu {
    &_cont {
      width: 100%;
      height: 100%;
      padding: 0.53125em 15px 60px;
      overflow-y: auto;
    }
    &_item {
      & + .menu_item { border-top: 1px solid #f6f1e4; }
      &_cont {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #a98e62;
        text-decoration: none;
        letter-spacing: 0.1em;
        line-height: 27px;
        padding: 14px 0 0.84375em;
      }
      .youtube {
        font-size: 0;
        height: 73px;
        background: url(../img/contents/youtube.png) no-repeat center center;
        background-size: 90px auto;
      }
      .language span {
        display: inline-block;
        padding-left: 24px;
        background: url(../img/contents/icon_lang.png) no-repeat left center;
        background-size: 16px auto;
      }
    }
  }
  .subMenu {
    padding-bottom: 0;
    &_cont {
      padding-bottom: 0.78125em;
    }
    &_item {
      margin-top: 7px;
      &_cont {
        display: block;
        font-size: 14px;
        color: #888;
        letter-spacing: 0.1em;
        line-height: 27px;
        text-decoration: none;
      }
    }
  }
}
.active #HeaderMenu_sp {
  top: 60px;
}

body[id^="PageCn"] #HeaderMenu_sp {
  .menu {
    &_item {
      .language span {
        background-position: left top 7px;
      }
    }
  }
}

/*####################　コンテンツ部　####################*/
#Wall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 45;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
.active #Wall {
  opacity: 1;
  pointer-events: auto;
}

/*　メインイメージ
-------------------------------------*/
#MainImg {
  .mainImg_cont {
    padding-top: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }
}

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
-------------------------------------*/
#TopicPath {
  text-align: left;
  margin: px auto 0;
  padding: 0 px;
  ol {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    list-style: none;
    font-family: helvetica,'arial black',arial,sans-serif;
    font-size: 15px;
    color: #000;
    line-height: 1;
    padding-left: 15px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      background: url(../img/contents/★アロー画像★) no-repeat left center;
      background-size: px auto;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      color: #222;
      &.home {
        padding-left: 0;
        background: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
}
/*　メイン
-------------------------------------*/
#Main {
  .pageTitle {
    font-style: italic;
    margin-bottom: 22px;
    h2 {
      font-size: 45px;
      color: #a98e62;
      letter-spacing: 0;
      line-height: 1;
      text-transform: uppercase;
    }
    span {
      display: inline-block;
      font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #888;
      letter-spacing: 0.05em;
      line-height: 16px;
      position: relative;
      &:before {
        display: inline-block;
        content: "/";
        font-style: normal;
        color: #dfdfdf;
        margin: 0 5px 0 13px;
      }
    }
  }
  ul {
    width: 100%;
    li {
      position: relative;
      padding-left: 15px;
      text-align: justify;
      &:before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        background-color: #7f3a12;
        border-radius: 50%;
        top: 0.84375em;
        left: 0;
      }
    }
  }
  table { width: 100%; }
  .post {
    &_item {
      padding-left: 0;
      &:before { display: none; }
      &_cont {
        display: block;
        color: #000;
        text-decoration: none;
        padding: 18px 0 13px;
        border-bottom: 1px solid #ededed;
        .time {
          display: block;
          font-size: 14px;
          font-style: italic;
          color: #9b7864;
          letter-spacing: 0.2em;
          line-height: 16px;
        }
        .title {
          font-size: 16px;
          letter-spacing: 0.025em;
          line-height: 26px;
          background: url(../img/contents/arrow_bw.png) no-repeat right center;
          background-size: 25px auto;
          padding-right: 55px;
          margin-top: 7px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  .moreBtn {
    display: block;
    width: 100%;
    height: 55px;
    background-color: #7f3a12;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.2em;
    line-height: 55px;
    text-align: center;
    text-decoration: none;
    padding-left: 0.2em;
  }
  span.note {
    display: block;
    font-size: 12px;
    color: #888;
    letter-spacing: 0;
    line-height: 24px;
    text-align: justify;
    position: relative;
    &:before {
      position: relative;
      content: '※';
    }
  }
  .logo {
    background-color: #fff;
    box-shadow: 0 4px 16px rgba(0,0,0,0.15);
    display: flex;
    flex-wrap: wrap;
    &_item {
      width: calc(50% - 1px);
      height: 123px;
      text-align: center;
      border-bottom: 1px solid #f6f1e4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      &:nth-of-type(2n) {
        width: calc(50% + 1px);
        border-left: 1px solid #f6f1e4;
      }
      &:nth-last-of-type(2),
      &:last-of-type { border-bottom-width: 0; }
      &:before { display: none; }
      img { display: inline-block; }
      span {
        display: block;
        font-size: 11px;
        color: #888;
        letter-spacing: 0.05em;
        line-height: 16px;
        margin-top: 12px;
      }
    }
  }
  .form {
    margin-top: 20px;
    padding-bottom: 30px;
    &_cont {
      width: 100%;
      tr {
        border-top: 1px solid #e9e0c9;
        &:first-of-type td { margin-top: 6px; }
      }
      th, td {
        display: block;
        width: 100%;
      }
      th {
        font-size: 16px;
        font-weight: normal;
        color: #906e38;
        padding-top: 14px;
        &:before {
          display: inline-block;
          box-sizing: border-box;
          content: '必須';
          width: 35px;
          height: 18px;
          background-color: #7f3a12;
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0.05em;
          line-height: 1;
          text-align: center;
          padding-top: 0.25em;
          margin-right: 9px;
          position: relative;
          top: -2px;
        }
      }
      td {
        margin-top: 7px;
        padding-bottom: 20px;
        input {
          display: block;
          width: 100%;
          height: 45px;
          background-color: #fff;
          padding: 6px 14px 5px;
          border: 1px solid #e9e0c9;
        }
        label {
          display: block;
          line-height: 40px;
          & + label { margin-top: 7px; }
        }
        .radio {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          span {
            display: block;
            width: 100%;
            height: 100%;
            background: url(../img/contents/radio_01.png) no-repeat center center;
            background-size: 40px auto;
            &.checked { background-image: url(../img/contents/radio_02.png); }
          }
          input {
            display: inline-block;
            opacity: 0;
            pointer-events: none;
          }
        }
        textarea {
          display: block;
          resize: vertical;
          width: 100%;
          min-height: 240px;
          background-color: #fff;
          padding: 10px 14px;
          border: 1px solid #e9e0c9;
        }
      }
    }
    &_button {
      display: block;
      width: 100%;
      max-width: 345px;
      height: 55px;
      background-color: #7f3a12;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.05em;
      line-height: 55px;
      margin: 20px auto 0;
    }
  }
  .video {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 57.39%;
    overflow: hidden;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  figure img { width: 100%; }

  input[type="button"],input[type="text"],
  input[type="submit"],input[type="email"],
  input[type="tel"], textarea{
    -webkit-appearance: none !important;
    border-radius: 0 !important;
  }
}

body[id^="PageCn"] #Main {
  .form {
    &_cont {
      th:before {
        content: '必选项目';
        width: 62px;
        padding-top: 0.2em;
        top: -1px;
      }
      tr:last-of-type th:before {
        content: '必选项目' !important;
      }
    }
  }
}

.pageConfirm #Main,
.pageThanks #Main {
  padding-top: 53px;
  padding-bottom: 10px;
  .pageTitle { margin-bottom: 30px; }
  .form {
    margin-top: 30px;
    &_cont {
      tr { border-color: #e5e5e5; }
    }
    &_return {
      display: inline-block;
      font-size: 14px;
      margin-top: 20px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .homeBtn {
    display: inline-block;
    width: 100%;
    max-width: 345px;
    height: 55px;
    background-color: #7f3a12;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.05em;
    line-height: 55px;
    text-align: center;
    text-decoration: none;
    margin: 40px auto 110px;
  }
}

.pageThanks #Main {
  .contBox {
    &_cont {
      text-align: center;
      h3 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
      }
      p { text-align: center; }
    }
  }
}

body[id^="PageCn"].pageConfirm #Main,
body[id^="PageCn"].pageThanks #Main {
  .pageTitle {
    span {
      font-family: 'Noto Serif SC', serif;
    }
  }
}

/*####################　フッタ　####################*/
#Footer {
  background-color: #503c30;
  .footer {
    &_box {
      border-bottom: 1px solid #604534;
    }
    &_box01 {
      padding: 60px 0 40px;
      .banner {
        display: block;
        width: 100%;
        background: #cdc2bc url(../img/contents/arrow_wh.png) no-repeat right 15px center;
        background-size: 25px auto;
        text-decoration: none;
        padding: 16px 15px 12px;
        position: relative;
        overflow: hidden;
        .jp {
          display: block;
          font-size: 12px;
          color: #4f3f14;
          letter-spacing: 0.05em;
          line-height: 1;
        }
        .en {
          font-size: 24px;
          font-weight: bold;
          color: #7f3a12;
          line-height: 1;
          letter-spacing: 0;
          text-transform: uppercase;
          opacity: 0.1;
          position: absolute;
          right: 0;
          bottom: -3px;
        }
        p {
          font-size: 19px;
          font-weight: bold;
          color: #fff;
          line-height: 24px;
          margin-top: 6px;
          transform: translateX(-2px);
        }
        & + .banner { margin-top: 15px; }
      }
    }
    &_box02 {
      padding: 30px 0 31px;
      text-align: center;
      .logo {
        display: block;
        width: 200px;
        margin: 0 auto;
        text-decoration: none;
        img { width: 200px; }
        span {
          display: block;
          font-size: 14px;
          color: #a98e62;
          letter-spacing: 0.05em;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .youtube {
        display: block;
        width: 90px;
        height: 21px;
        background: url(../img/footer/youtube.png) no-repeat center;
        background-size: 90px auto;
        font-size: 0;
        text-decoration: none;
        margin: 24px auto 0;
      }
      small {
        display: block;
        font-size: 12px;
        color: #8d705e;
        letter-spacing: 0.05em;
        line-height: 18px;
        margin-top: 25px;
        a { color: #8d705e; }
      }
    }
    &_copy {
      text-align: center;
      line-height: 37px;
      small {
        display: inline-block;
        font-size: 10px;
        color: #8d705e;
        letter-spacing: 0;
      }
    }
  }
}
#FooterInfo {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #392a22;
  z-index: 1;
  .footerInfo_cont {
    display: flex;
    max-width: 375px;
    align-items: center;
    padding: 0.78125em 15px 0.78125em 14px;
    margin: 0 auto;
    div { width: 50%; }
    a { text-decoration: none; }
  }
  .telBox {
    background: url(../img/contents/icon_05.png) no-repeat left center;
    background-size: 32px auto;
    padding: 3px 0 2px 40px;
    transform: translateY(-1px);
    text-align: left;
    span {
      display: block;
      font-size: 9px;
      color: #dfb36c;
      letter-spacing: 0.04em;
      line-height: 1.2;
      margin-bottom: 1px;
    }
    a {
      display: block;
      font-size: 18px;
      font-weight: bold;
      color: #dfb36c;
      letter-spacing: 0.04em;
      line-height: 1;
      margin-top: -1px;
    }
  }
  .formBox {
    padding-left: 0.46875em;
    a {
      display: block;
      height: 35px;
      background: #dfb36c url(../img/contents/arrow_02.png) no-repeat right 10px top 13px;
      background-size: 7px auto;
      font-size: 14px;
      font-weight: bold;
      color: #392a22;
      letter-spacing: 0;
      line-height: 36px;
      text-align: left;
      padding-left: 10px;
    }
  }
}

.pageThanks #FooterInfo { display: none; }

body[id^="PageCn"] #FooterInfo {
  .formBox {
    a {
      line-height: 35px;
      letter-spacing: 0.05em;
    }
  }
}

/*#################### PC ####################*/
@media print, screen and (min-width: 850px) {
  body {
    min-width: 1000px;
  }

  a[href^="tel"] {
    cursor: text;
    text-decoration: none;
  }

  .click {
    cursor: pointer;
  }

  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }

  /*####################　枠設定　####################*/
  #Header, #Container, #Footer {
    min-width: 1000px;
  }
  .innerBasic{
    width: 1000px;
    margin: 0 auto;
    padding: 0;
  }

  /*####################　コンテンツ部　####################*/
  #Wall { display: none; }

  /*　メインイメージ
-------------------------------------*/
  #MainImg {
    .mainImg_cont { padding-top: 41.40625%; }
  }

  /*　パンくず　
  -------------------------------------*/
  #TopicPath {
    margin: px auto 0;
    width: 1000px;
    ol li {
      padding-left: 15px;
      font-size: 15px;
      a:hover {
        text-decoration: underline;
      }
    }
  }

  /*　メイン
  -------------------------------------*/
  #Main {}

  /*####################　フッタ　####################*/
  #Footer{}
}


@media print, screen and (max-width: 360px) {
  #FooterInfo {
    .telBox { padding-left: 39px; }
  }
}
@media print, screen and (max-width: 320px) {
  #FooterInfo {
    z-index: 1;
    .formBox {
      a {
        background-position: right 3px center;
        padding-left: 4px;
      }
    }
  }
}


// safari
_:lang(x)+_:-webkit-full-screen-document, #FooterInfo .telBox span { letter-spacing: 0.14em; }
_:lang(x)+_:-webkit-full-screen-document, #FooterInfo .telBox a { letter-spacing: 0.03em; }

// IE
@media print, screen and (max-width: 696px) {
  _:-ms-lang(x), body {
    width: 100%;
    overflow-x: hidden;
  }
}

/*end*/